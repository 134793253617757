.main {
    height: 100%;
    border-right: 0;
    color: #FFFFFF;
}

.top_menu {
    height: 85%;
    color: #FFFFFF;
}

.bottom_menu {
    height: 7%;
    color: #FFFFFF;
}