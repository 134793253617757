.headerContainer {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 80, 179, 1);
}

.headerLeft {
    width: 400px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.logoContainer {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.logo {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.projectSelect {
    width: 40%;
    display: block;
}

.headerRight {
    width: 190px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
}

.notiContainer {
    display: flex;
}

.iconBell {
    width: 32px;
}

.avatarContainer {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center
}

.userNameText {
    display: block;
}

.avatarContainer:hover {
    cursor: pointer;
}

@media only screen and (max-width: 755px) {
    .logoContainer {
        width: 75%;
    }
    .headerContainer {
        padding-inline: 10px;
    }
    .headerRight {
        justify-content: flex-end;
        gap: 40px;
        margin-right: 0;
    }
    .userNameText {
        display: none;
    }
    .projectSelect {
        display: none;
    }
}